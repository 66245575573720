const faqs = [
  {
    question: " ¿Qué requisitos piden para sacar el pasaporte mexicano?",
    answer: (
      <div>
        <p>-  Ciudadanía Mexicana: Para obtener un pasaporte mexicano, es requisito fundamental ser ciudadano mexicano. Esto significa que debes ser nacido en territorio mexicano o naturalizado, ya sea por nacimiento o por adopción. Si aún no tienes la ciudadanía, deberás gestionarla antes de solicitar el pasaporte.</p><br/>
        <p>- Acta de Nacimiento: El acta de nacimiento es uno de los documentos más importantes para demostrar tu ciudadanía. Debes presentar una copia certificada del acta de nacimiento emitida por el Registro Civil mexicano. Asegúrate de que el documento sea legible y no presente enmiendas ni tachaduras.</p><br/>
        <p>- Identificación Oficial: Además del acta de nacimiento, se requiere una identificación oficial vigente. Puedes presentar tu credencial para votar del Instituto Nacional Electoral (INE) o una licencia de conducir emitida por alguna entidad gubernamental mexicana.</p><br/>
        <p>- Comprobante de Domicilio: Necesitarás un comprobante de domicilio reciente para demostrar que resides en México. Puedes utilizar recibos de servicios públicos (agua, luz, gas), estados de cuenta bancarios o cartas de residencia expedidas por las autoridades municipales.</p><br/>
        <p>- Fotografías: Es necesario presentar fotografías recientes que cumplan con ciertas especificaciones. Las fotografías deben ser a color, de frente, con fondo blanco, sin lentes oscuros ni gorras, y con el rostro completamente visible. Generalmente, las medidas requeridas son 4.5 x 3.5 centímetros.</p><br/>
        <p>- Pago de Derechos: La emisión del pasaporte mexicano tiene un costo asociado, por lo que deberás realizar el pago de derechos correspondiente. Los montos pueden variar, así que es recomendable verificar el costo actual antes de efectuar el pago.</p><br/>
        <p>- Presencia del Solicitante: En el momento de la solicitud, deberás acudir personalmente a la oficina de la Secretaría de Relaciones Exteriores (SRE) o a alguna delegación autorizada para realizar el trámite. Los menores de edad deben asistir acompañados de sus padres o tutores legales.</p><br/>
       
    
      </div>
    ),
  },

 
];

export default faqs;
